<template>
  <div class="row row_detail">
    <!-- описание -->
    <div class="col-12 order-2 col-sm-7 offset-sm-0 order-sm-2 col-md-6 order-md-2 offset-md-0 col-lg-6 col-xl-6 py-1">
      <span v-if="item.isnew" class="badge bg-warning text-dark">НОВАЯ</span>
      <!-- заголовок -->
      <a class="fs-6" :href="item.link">{{ item.title }}</a> <span class="fw-light">-</span>
      <span class="text-nowrap fw-medium" :class="item.stock.color">{{ item.stock.name }}</span>
      <template v-if="item.compatible"
        ><br /><span class="text-warning fw-light">Совместимая деталь от другой модели.</span><br
      /></template>
      <!-- описание -->
      <div class="row descr d-none d-md-flex">
        <template v-for="(value, key) in item.params">
          <div :key="key + 'title'" class="col-3">{{ key }}</div>
          <div :key="key + 'value'" class="col-9">{{ value }}</div></template
        >
        <template v-if="Object.keys(item.warehouse).length"
          ><div class="col-3">Склад</div>
          <div class="col-9 text-info-emphasis">
            <template v-for="(amount, place) in item.warehouse"
              ><span :key="place" v-if="amount > 0" class="d-inline-block px-1 bg-warning-subtle"
                >{{ place }} - {{ amount }}шт.</span
              ></template
            >
          </div>
        </template>
      </div>

      <!-- цена -->
      <div class="price d-flex d-sm-block d-md-none justify-content-between mt-2">
        <div class="value mb-3">
          <template v-if="Object.keys(item.prices).length">
            <template v-if="item.prices.price_orig > 0 && item.prices.price_orig != item.prices.price"
              ><span class="value_new">{{ item.prices.price }}</span
              ><span class="sign">&nbsp;руб.</span>
              <span class="value_old">{{ item.prices.price_orig }}&nbsp;руб.</span></template
            >
            <span v-else class="value_normal">{{ item.prices.price }}</span
            ><span class="sign">&nbsp;руб.</span>
          </template>
          <span v-else class="no"><small>Позвоните нам, чтобы уточнить цену</small></span>
        </div>
        <button-add-to-cart
          v-if="item.available"
          :cart="cart"
          :contentid="parseInt(item.buttons.id)"
          :extra="item.buttons.extra"
          v-on:add="addToCart($event)"></button-add-to-cart>
      </div>
    </div>

    <!-- фотки -->
    <div
      class="col-10 offset-1 order-1 col-sm-5 offset-sm-0 order-sm-1 col-md-3 order-md-1 offset-md-0 col-lg-3 col-xl-2 py-1 d-flex flex-column justify-content-center">
      <template v-if="item.images.arr.length">
        <template v-for="(value, key) in item.images.arr">
          <a
            v-if="key == 0"
            :key="key"
            :data-fancybox="'fancybox-thumb[' + value.code + ']'"
            :data-thumb="value.thumb"
            :title="`#${key + 1}, ${item.images.title}`"
            :data-caption="`#${key + 1}, ${item.images.title}`"
            :href="value.wrapper"
            class="fancybox-thumb w-100 d-inline-block img-hover position-relative">
            <div class="images">
              <template v-for="(val, key) in item.images.arr">
                <img
                  v-if="key < 4"
                  :key="key"
                  :src="val.thumb"
                  :alt="`#${key + 1}, ${item.images.title}`"
                  loading="lazy"
                  class="img-fluid w-100" />
              </template>
            </div>
            <span v-for="(val, key) in item.images.icons" :class="key" :key="key"><img :src="val" alt="" /></span>
          </a>
          <a
            v-else
            :key="key"
            :data-fancybox="'fancybox-thumb[' + value.code + ']'"
            :data-thumb="value.thumb"
            :title="`#${key + 1}, ${item.images.title}`"
            :data-caption="`#${key + 1}, ${item.images.title}`"
            :href="value.wrapper"
            class="fancybox-thumb d-none"></a>
        </template>
      </template>
      <img v-else loading="lazy" class="img-fluid w-100" :src="item.images.noimage" alt="Нет фото" />
    </div>

    <!-- цена -->
    <div class="col-12 order-3 col-sm-3 order-sm-3 col-md-3 order-md-3 col-lg-3 text-center py-1 d-none d-md-block">
      <div class="price">
        <div class="value mb-3">
          <template v-if="Object.keys(item.prices).length">
            <template v-if="item.prices.price_orig > 0 && item.prices.price_orig != item.prices.price"
              ><span class="value_new">{{ item.prices.price }}</span
              ><span class="sign">&nbsp;руб.</span>
              <span class="value_old">{{ item.prices.price_orig }}&nbsp;руб.</span></template
            >
            <span v-else class="value_normal">{{ item.prices.price }}</span
            ><span class="sign">&nbsp;руб.</span>
          </template>
          <span v-else class="no"><small>Позвоните нам, чтобы уточнить цену</small></span>
        </div>
        <button-add-to-cart
          v-if="item.available"
          :cart="cart"
          :contentid="parseInt(item.buttons.id)"
          :extra="item.buttons.extra"
          v-on:add="addToCart($event)"></button-add-to-cart>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonAddToCart from './button-addtocart.vue';

export default {
  name: 'CatalogRow',
  components: {
    ButtonAddToCart,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    cart: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  methods: {
    addToCart: function (ev) {
      this.$root.$emit('cart-add', ev);
    },
  },
};
</script>
