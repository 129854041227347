<template>
  <button type="button" class="btn btn-primary" v-on:click="$root.$emit('load-more', {})">
    {{ btn_loadmore }}
  </button>
</template>
<script>
export default {
  data: function () {
    return {
      //
    };
  },
  props: {
    page: {
      type: Number,
      default: 0,
    },
    onpage: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    btn_loadmore() {
      const btntext1 =
        this.total - (this.page + 1) * this.onpage > this.onpage
          ? this.onpage
          : this.total - (this.page + 1) * this.onpage;
      const btntext2 = this.total - (this.page + 1) * this.onpage;
      return 'Ещё ' + btntext1 + ' из ' + btntext2;
    },
  },
};
</script>
