<template>
  <!-- placeholder -->
  <div v-if="loading" class="mt-1 mb-4" aria-hidden="true">
    <div class="row py-2 row_detail placeholder-glow">
      <div data-descr="галочка" class="col col-2 order-1 col-sm-1 order-sm-1">
        <span class="placeholder placeholder-lg col-4"></span>
      </div>
      <div data-descr="описание" class="col col-7 order-5 col-sm-7 order-sm-3 col-md-4 col-xl-5 mb-2">
        <span class="placeholder col-12 bg-info"></span>
        <!-- туть -->
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
      </div>
      <div data-descr="фотки" class="col col-4 order-4 col-sm-3 offset-sm-0 order-sm-2 col-md-3 col-lg-3 col-xl-2">
        <span class="placeholder col-12"><br /><br /><br /><br /></span>
      </div>
      <div data-descr="цена" class="col col-8 order-2 col-sm-10 order-sm-4 col-md-3 col-lg-3 text-right prices">
        <span class="placeholder col-12"></span>
      </div>
      <div data-descr="кнопка" class="col col-2 order-3 col-sm-2 order-sm-5 col-md-1">
        <!-- туть -->
      </div>
    </div>
  </div>
  <p v-else-if="error">{{ msgError }}</p>
  <div v-else>
    <catalog-row v-for="item in items" :key="item.strcode" :item="item" :cart="cart"></catalog-row>

    <div v-if="onpage < total && (page + 1) * onpage < amount" class="my-3 text-center">
      <div v-if="loadingmore" class="progress">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          :aria-valuenow="progress"
          aria-valuemin="0"
          aria-valuemax="100"
          :style="`width: ${progress}%`"></div>
      </div>
      <button-load-more v-else :page="page" :onpage="onpage" :total="amount" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import ButtonLoadMore from './button-loadmore.vue';
import CatalogRow from './catalog-row.vue';

export default {
  name: 'CatalogSearchResult',
  components: {
    ButtonLoadMore,
    CatalogRow,
  },
  props: {
    cart: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  data: function () {
    return {
      loading: true,
      error: false,
      loadingmore: false,
      //
      search: '',
      searching: false,
      //
      items: [],
      msgError: '',
      // страницы
      page: 0,
      onpage: 25,
      amount: 0,
      //
      progress: 0,
    };
  },
  methods: {
    doSearch: function () {
      if (this.searching == true) {
        return;
      }

      this.searching = true;

      // что искать
      let s = null;
      s = localStorage.getItem('search');
      if (s === null) {
        // нет кода для поиска
        this.error = true;
        this.msgError =
          'Требуется указать код производителя или артикул для поиска. Можно указать часть кода, но не меньше 4 символов.';
        this.$bs5utils.Snack.show(
          'warning',
          'Невозможно выполнить поиск, нужно указать код производителя или артикул товара.',
          this.$store.getters.delay.warning
        );
        return;
      }

      this.search = s;

      if (this.search.length == 0) {
        this.error = true;
        this.msgError =
          'Требуется указать код производителя или артикул для поиска. Можно указать часть кода, но не меньше 4 символов.';
        this.$bs5utils.Snack.show(
          'warning',
          'Невозможно выполнить поиск, нужно указать код производителя или артикул товара.',
          this.$store.getters.delay.warning
        );
      }

      if (this.search.length < 4) {
        this.error = true;
        this.msgError = 'Для поиска введите не меньше 4 символов.';
        this.$bs5utils.Snack.show(
          'warning',
          'Для поиска нужно указать минимум 4 символа.',
          this.$store.getters.delay.warning
        );
        return;
      }

      this.loading = true;
      this.error = false;

      axios
        .get(`${this.$store.getters.urlsearch}/${this.search}?onpage=${this.onpage}`)
        .then((res) => {
          if (res.data.status) {
            // ok
            this.items = res.data.result;

            this.page = res.data.page;
            this.onpage = res.data.onpage;
            this.amount = res.data.amount;

            setTimeout(() => {
              this.$root.$emit('cart-sync', {});
            }, 150);
          } else {
            // not ok
            this.error = true;
            this.msgError = res.data.result;
          }
        })
        .catch((err) => {
          this.items = [];
          this.page = 0;

          this.error = true;
          this.msgError = 'Ошибка при поиске, попробуйте позже, пожалуйста.';
          console.error(err);
        })
        .finally(() => {
          setTimeout(() => {
            this.searching = false;
            this.loading = false;
          }, 150);
        });
    },
    loadMore: function () {
      if (this.searching == true) {
        return;
      }

      this.searching = true;

      if (this.search.length < 4) {
        this.error = true;
        this.msgError = 'Для поиска введите не меньше 4 символов.';
        this.$bs5utils.Snack.show(
          'warning',
          'Для поиска нужно указать минимум 4 символа.',
          this.$store.getters.delay.warning
        );
        return;
      }

      this.progress = 0;
      const p = setInterval(() => {
        this.progress++;
        if (p == 100) {
          clearInterval(p);
        }
      }, 500);

      this.loadingmore = true;
      this.page = this.page + 1;

      axios
        .get(`${this.$store.getters.urlsearch}/${this.search}?page=${this.page}&onpage=${this.onpage}`)
        .then((res) => {
          if (res.data.status) {
            // ok
            this.items = this.items.concat(res.data.result);

            this.page = res.data.page;
            this.onpage = res.data.onpage;
            this.amount = res.data.amount;

            setTimeout(() => {
              this.$root.$emit('cart-sync', {});
            }, 150);
          } else {
            // not ok
            this.error = true;
            this.msgError = res.data.result;
          }
        })
        .catch((err) => {
          this.items = [];
          this.page = 0;

          this.error = true;
          this.msgError = 'Ошибка при поиске, попробуйте позже, пожалуйста.';
          console.error(err);
        })
        .finally(() => {
          setTimeout(() => {
            this.searching = false;
            this.loadingmore = false;
          }, 150);
          clearInterval(p);
        });
    },
  },
  created() {
    // сообщаем, что на странице поиска
    this.$root.$emit('page-search', {});

    // поискать
    this.$root.$on('do-search', () => {
      this.doSearch();
    });
  },
  mounted() {
    this.$root.$on('load-more', () => {
      this.loadMore();
    });
  },
};
</script>

<style scoped>
.progress {
  width: 50%;
  margin-left: 25%;
}
</style>
