<template>
  <div>
    <catalog-row v-for="item in products" :key="item.strcode" :item="item" :cart="cart"></catalog-row>

    <div v-if="onpage < total && (page + 1) * onpage < total" class="my-3 text-center">
      <div v-if="loadingmore" class="progress">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          :aria-valuenow="progress"
          aria-valuemin="0"
          aria-valuemax="100"
          :style="`width: ${progress}%`"></div>
      </div>
      <template v-else>
        <button-load-more :page="page" :onpage="onpage" :total="total" />
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import ButtonLoadMore from './button-loadmore.vue';
import CatalogRow from './catalog-row.vue';

export default {
  name: 'CatalogLoadMore',
  data: function () {
    return {
      listtypes: ['bycategory', 'bydetails'],
      loadingmore: false,
      // список товаров для показа в "показать ещё"
      products: [],
      //
      page: 0,
      //
      progress: 0,
    };
  },
  props: {
    cart: {
      type: Object,
      default() {
        return {};
      },
    },
    //
    listtype: {
      type: String,
      default: '',
    },
    vendor: {
      type: Number,
      default: 0,
    },
    model: {
      type: Number,
      default: 0,
    },
    group: {
      type: Number,
      default: 0,
    },
    subgroup: {
      type: Number,
      default: 0,
    },
    // страницы и количество
    onpage: {
      type: Number,
      default: 25,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ButtonLoadMore,
    CatalogRow,
  },
  methods: {
    loadMore: function () {
      if (this.listtype != '' && this.listtypes.includes(this.listtype)) {
        this.progress = 0;
        const p = setInterval(() => {
          this.progress++;
          if (p == 100) {
            clearInterval(p);
          }
        }, 250);

        const data = {
          listtype: this.listtype,
          //
          vendor: this.vendor,
          model: this.model,
          group: this.group,
          subgroup: this.subgroup,
          //
          page: this.page + 1,
          onpage: this.onpage,
        };

        this.loadingmore = true;

        axios
          .get(`${this.$store.getters.urlproductsmore}?` + new URLSearchParams(data).toString())
          .then((res) => {
            if (res.status == 200) {
              // ok
              this.products = this.products.concat(res.data.items);
              this.page++;
            } else {
              // not ok
              this.$bs5utils.Snack.show('warning', 'Ошибка в ответе сервера.', this.$store.getters.delay.warning);
            }
          })
          .catch((err) => {
            this.$bs5utils.Snack.show(
              'warning',
              'Не удалось загрузить список товаров, обновите страницу и попробуйте снова.',
              this.$store.getters.delay.warning
            );
            console.error(err);
          })
          .finally(() => {
            setTimeout(() => {
              this.loadingmore = false;
            }, 150);
            clearInterval(p);
          });
      } else {
        this.$bs5utils.Snack.show('danger', 'Непонятно что надо загрузить.', this.$store.getters.delay.error);
      }
    },
  },
  mounted() {
    this.$root.$on('load-more', () => {
      this.loadMore();
    });
  },
};
</script>

<style scoped>
.progress {
  width: 50%;
  margin-left: 25%;
}
</style>
